import Vue from 'vue'
import i18next from 'i18next'
import VueI18Next from '@panter/vue-i18next'

import { de, en, fr, it } from './locales.json'

const resources = {
  de,
  en,
  fr,
  it,
}

Vue.use(VueI18Next)

i18next.init({
  lng: 'de',
  fallbackLng: 'de',
  whitelist: ['de', 'fr', 'it', 'en'],
  ns: [
    'all',
    'activerecord',
    'app_settings',
    'anamneses',
    'calendar',
    'common',
    'documents',
    'patientsIndex',
    'positionsOverview',
    'taxSummary',
  ],
  defaultNS: 'anamneses',
  resources,
  interpolation: {
    prefix: '%{',
    suffix: '}',
    prefixEscaped: '%{',
  },
  saveMissing: true,
})

const i18n = new VueI18Next(i18next)

export function useTranslation(namespaces) {
  // Doc: https://www.i18next.com/overview/api#getfixedt
  const t = i18next.getFixedT(null, namespaces)
  return { t, i18next }
}

export default i18n
