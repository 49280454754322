
<div>
  <v-autocomplete
    v-if="!patientCreation"
    v-model="model"
    v-bind="defaultInputBindings"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :prepend-inner-icon="prependInnerIcon"
    :no-data-text="noDataTextTranslated"
    :cache-items="false"
    :class="cssClassName"
    item-text="fullnameWithNo"
    item-value="id"
    no-filter
    v-on="defaultHandlers"
    @input="$emit('input', $event)"
  >
    <template #item="{ item, attrs }">
      <div v-bind="attrs">
        <span>{{ item.fullnameWithNo }}, </span>
        <span class="grey--text ml-1"
          >*{{ item.yearOfBirth }}
          {{
            showLocationIfDifferent && item.defaultLocation.id != locationId
              ? `(${item.defaultLocation.shortname})`
              : ''
          }}</span
        >
      </div>
    </template>
    <template
      v-if="
        enableNewPatient &&
        !$featureFlag.feature_flags_enable_external_patient_data
      "
      #append-item
    >
      <div
        class="px-4 v-list-item v-list-item--link theme--light"
        @click="setPatientCreation(true)"
      >
        <span>
          {{ $t('calendar:eventForm.createNewPatient') }}
        </span>
      </div>
    </template>
  </v-autocomplete>
  <div v-if="patientCreation" class="pb-8">
    <d-input-text
      v-model="newPatientData.prename"
      label="activerecord:attributes.person.prename"
    />
    <d-input-text
      v-model="newPatientData.name"
      label="activerecord:attributes.person.name"
    />
    <d-input-text
      v-model="newPatientData.phone"
      label="activerecord:attributes.person.phone"
    />
    <d-input-text
      v-model="newPatientData.mobile"
      label="activerecord:attributes.person.mobile"
      :hide-details="true"
    />
    <v-row align="center">
      <v-col cols="12" class="pt-5">
        <div class="text-right">
          <d-btn label="cancel" role="secondary" @click="cancelNewPatient" />
          <d-btn
            label="patient_save"
            align-right
            class="ml-2"
            :loading="loading"
            @click="createNewPatient"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</div>
